import { fade, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => (
  {
    toTop: {
      zIndex: 1000,
      position: 'fixed',
      bottom: '3vh',
      backgroundColor: fade(theme.palette.secondary.main, 0.95),
      color: 'black',
      '&:hover, &.Mui-focusVisible': {
        transition: '0.3s',
        color: '#397BA6',
        backgroundColor: '#DCDCDC'
      },
      [theme.breakpoints.up('xs')]: {
        right: '5%',
      },
    }
  })
);
