import fetch from 'cross-fetch';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-boost';
import { APP_LANGUAGE } from '../i18n';

export const client = new ApolloClient({
  link: new createUploadLink({
    uri: process.env.GATSBY_BACKEND_URL,
    fetch,
    headers: {
      'Accept-Language': typeof window !== 'undefined' ?  localStorage.getItem(APP_LANGUAGE) : 'en-us'
    }
  }),
  cache: new InMemoryCache()
});
