import React, { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useStyles } from './styles';

const Scroll = ({showBelow}) => {
  const classes = useStyles();
  const [show, setShow] = useState(showBelow ? false : true);

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  const handleClick = () => {
    window[`scrollTo`]({top: 0, behavior: `smooth`});
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });

  return (
    <div>
      {show &&
      <IconButton onClick={handleClick} className={classes.toTop} aria-label="to top" component="span">
        <ExpandLessIcon color={'primary'}
         fontSize={'large'} />
      </IconButton>
      }
    </div>
  );
};
export default Scroll;
