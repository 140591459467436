import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import resourceEN from './en';
import resourceES from './es';

export const BACKEND_FRONTEND_LANGUAGES = {
  es: 'es',
  'en-us': 'en',
};

const checkUndefined = (language) => {
  return typeof BACKEND_FRONTEND_LANGUAGES[language] === `undefined`
    ||  BACKEND_FRONTEND_LANGUAGES[language] === 'undefined';
}

export const APP_LANGUAGE = 'language';

let defaultLanguage;

if (typeof navigator !== `undefined`) {
  defaultLanguage = (navigator && navigator.language) ? navigator.language.toLowerCase() : '';
}

if (typeof window !== 'undefined') {
  defaultLanguage = localStorage.getItem(APP_LANGUAGE);
}

defaultLanguage = checkUndefined(defaultLanguage) ? null : defaultLanguage

const resources = {
  es: {
    translation: resourceES,
  },
  en: {
    translation: resourceEN,
  },
};


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: BACKEND_FRONTEND_LANGUAGES[defaultLanguage || 'en-us'],
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
