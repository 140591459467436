/*
  English Resource
 */

const resourceEn = {
  // Home
  homeTitle: 'Software Development and IT Outsourcing',
  homeSubTitle: 'Coding ideas as digital solutions',

  // Services
  ourServices: 'Our services',
  ourServicesDescription: 'We work with our clients proactively in the development of their initiatives.',
  softwareDevelopment: 'Software Development',
  softwareDevelopmentDescription: 'Nowadays, everyone recognizes how software is now necessary and the oldest '
    + 'barriers are broken with technology. To develop with quality, speed and creativity, '
    + 'check our software development services.',
  softwareTesting: 'Software Testing',
  softwareTestingDescription: 'Quality is one of the most important things in any software and also it\'s one of the '
    + 'main cores of our business. Our software QA & testing services can help to make sure that you have a quality product.',
  consultancyInnovation: 'Consultancy & Innovation',
  consultancyInnovationDescription: 'Stay updated and at the forefront is a priority to compete in the global market. '
    + 'Dekoding’s innovation and consulting services combine our in-depth technical expertise, with Agile development, '
    + 'a great team, and Design Thinking to make your business part of the new world.',
  userExperience: 'User Experience',
  userExperienceDescription: 'Catching the customer at first sight, is a crucial step that determines the success '
    + 'or failure of your application. For us, UI and UX is a priority to develop any solution.',
  cloudServices: 'Cloud Services',
  cloudServicesDescription: 'Cloud computing comes to stay here and makes your company more nimble, flexible, '
    + 'and responsive to changing market demands. With our experience and knowledge, you will be part of this technological advance.',

  // Why dekoding
  whyDekoding: 'Why Dekoding ?',
  // purposeTitle: 'International Presence',
  purposeDescription: 'We have a trained team with years of experience that focuses on providing quality '
    + 'services and solutions, having immediate control of all of our processes from start '
    + 'to finish making our customers get their goals with the final product',

  // Stories
  customerStories: 'Some of our customer stories',
  storiesCard1: 'Ensuring a compelling user experience for a global entertainment company',
  storiesCard1Description: 'In today\'s world, software products must delight their users. To develop with speed and creativity, look to our software development services.',
  storiesCard2: 'Ensuring a compelling user experience for a global entertainment company',
  storiesCard2Description: 'In today\'s world, software products must delight their users. To develop with speed and creativity, look to our software development services.',
  storiesCard3Development: 'Ensuring a compelling user experience for a global entertainment company',
  storiesCard3Description: 'In today\'s world, software products must delight their users. To develop with speed and creativity, look to our software development services.',
  storiesCard4Development: 'Ensuring a compelling user experience for a global entertainment company',
  storiesCard4Description: 'In today\'s world, software products must delight their users. To develop with speed and creativity, look to our software development services.',
  readStory: 'Read the story',
  readMore: 'Read More',

  // Industries
  industries: 'Industries',
  industriesDescription: 'From the finance to the agriculture industry, we have collaborated with our '
    + 'customers to create quality digital solutions. '
    + 'Thus, we have collected knowledge from multiple markets to help you build a product that will have impact in the market.',
  industriesCard1: 'Why Dekoding?',
  industriesCard1Description: 'We have a trained team with years of experience that focuses on providing quality '
    + 'services and solutions, having immediate control of all of our processes from start to finish making our '
    + 'customers get their goals with the final product.',
  industriesCard2: 'Mision',
  industriesCard2Description: 'Provide IT solutions to every type of industry in the Software development and IT '
    + 'outsourcing giving quality services and under the highest quality standards.',
  industriesCard3: 'Vision',
  industriesCard3Description: 'Be leaders in the Peruvian market by '
    + 'launching our software projects with the help of our excellent team to get better profits along the way.',
  industriesCard4: 'Company Values',
  industriesCard4Description: 'Respect: We listen, understand y value each other looking for harmony into our interpersonal, '
    + 'work and commercial relationships.  <0 /> Honesty: we make everything with transparency and righteousness.  <0 /> '
    + 'Commitment: Our team is fully committed to our clients to provide quality services',

  // Location
  ourLocation: 'Our Locations',
  ourLocationDescription: 'One development center in South America and completely remote.',

  // Industries Services
  ourServicesIndustries: 'We provide end-to-end software and mobile application development services. We take you from creating the ' +
    'concept and design, through to developing and delivering the final product that will delight your customers.',
  cardList11: 'Product Development Services',
  cardList12: 'Mobile Development',
  cardList13: 'Software Architecture',
  cardList21: 'Software QA & Testing',
  cardList22: 'Automation Testing',
  cardList23: 'Mobile Testing',
  cardList31: 'Center of Excellence',
  cardList32: 'Artificial Intelligence & Machine Learning',
  cardList33: 'Blockchain Services',
  cardList41: 'Agile UX Services',
  cardList42: 'UX Center of Excellence',
  cardList51: 'Cloud software development',
  cardList52: 'Cloud management services',
  cardList53: '',

  // Lets get to work
  letsGetToWork: 'Let\'s get to work',
  contactUs: 'Contact Us',
  letsGetToWorkDescription: 'Our expertise will help you succeed in any market. Contact us and one of our ' +
    'experts will reach out to you to discover how we can lead your project to success.',

  // Services Contact Us
  servicesContactUs: 'Our expertise will help you succeed in digital',

  // Our industry expertise
  ourIndustryExpertise: 'Our industry experience',
  ourIndustryExpertiseDescription: 'Worldwide companies have trusted us to help them build successful products. '
    + 'Technology can be used in all industries to boost productivity and improve effectiveness and efficiency, '
    + 'saving budget and most important saving time.',
  ourIndustryDescription: 'About Us',
  ourIndustrySubDescription: 'We started as a local company and now we carry out projects abroad',
  industryExpertiseCard1: 'Financial',
  industryExpertiseCard1Description: 'Banks and insurers have employed the same relatively static, highly profitable '
    + 'business models. But today they find themselves confronted on all sides by innovators seeking to disrupt the business.',
  industryExpertiseCard2: 'Media and Entertainment',
  industryExpertiseCard2Description: 'With technology shaking the industry landscape, content creators are increasingly '
    + 'looking to re-engineer their media supply chains for greater agility, flexibility, operational efficiencies and cost benefits.',
  industryExpertiseCard3: 'Healthcare',
  industryExpertiseCard3Description: 'Streamlining physicians’ work, optimizing systems, improving patient outcomes, '
    + 'reducing human error, and lowering costs through technology.',
  industryExpertiseCard4: 'Education',
  industryExpertiseCard4Description: 'Technology provides teachers, parents and students with easy-to-access information, accelerated learning, ' +
    'and fun opportunities to practice what they have learned.',
  industryExpertiseCard5: 'Agriculture',
  industryExpertiseCard5Description: 'One of the oldest industries can '
    + 'overcome increasing demand and disruptive forces by the use of technology to improve its performance in many ways.',
  industryExpertiseCard6: 'Real Estate',
  industryExpertiseCard6Description: 'By the increasing investment on Realtech, traditional operators '
    + 'will come under increasing pressure to adapt or perish. The new battleground in real estate is technology.',

  // See Also
  seeAlso: 'See Also',
  seeAlsoItem1: 'Lorem ipsum',
  seeAlsoSubItem11: 'Lorem ipsum',
  seeAlsoSubItem11Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem12: 'Lorem ipsum',
  seeAlsoSubItem12Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem13: 'Lorem ipsum',
  seeAlsoSubItem13Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoItem2: 'Lorem ipsum',
  seeAlsoSubItem21: 'Lorem ipsum',
  seeAlsoSubItem21Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem22: 'Lorem ipsum',
  seeAlsoSubItem22Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem23: 'Lorem ipsum',
  seeAlsoSubItem23Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoItem3: 'Lorem ipsum',
  seeAlsoSubItem31: 'Lorem ipsum',
  seeAlsoSubItem31Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem32: 'Lorem ipsum',
  seeAlsoSubItem32Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem33: 'Lorem ipsum',
  seeAlsoSubItem33Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoItem4: 'Lorem ipsum',
  seeAlsoSubItem41: 'Lorem ipsum',
  seeAlsoSubItem41Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem42: 'Lorem ipsum',
  seeAlsoSubItem42Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem43: 'Lorem ipsum',
  seeAlsoSubItem43Description: 'Lorem ipsum dolor sit amet, consetetur ...',

  // Careers
  careerTitle: 'Careers',
  careerDescription: 'Be part of our staff of talented members',
  careers: 'Job Description',
  careersDescription: 'Check our profiles and choose the one that fits you more',
  careerItem1: 'Product Development Services',
  careerItem2: 'Mobile Development',
  careerItem3: 'Software Architecture',

  // Career form
  fullName: 'Full Name',
  personalEmail: 'Personal Email',
  message: 'Message',
  cellphone: 'Cellphone',
  email: 'Email',
  validCellphone: 'Cellphone number invalid',
  validEmail: 'Email invalid',
  salaryPerMonth: 'Salary expectation per month',
  salaryInitInvalid: 'Salary amount invalid',
  fieldToAttachCV: 'Field to attach CV',
  formFileMessage: 'PDF or Doc file',
  formMessage: 'The contents of this form and any attachments are confidential. They are intended only for our HR team.',
  sendForm: 'Send form',
  send: 'Send',
  leadDeveloper: 'Lead Developer',
  leadDeveloperDescription: 'As a lead developer, you would be responsible for directing the development '
    + 'team in the design, development, coding, testing and debugging of applications. '
    + 'You would also coordinate the development staff\'s schedule and ensure effective '
    + 'communication between team members and other IT functional areas.',
  backEndDeveloper: 'Back-End Developer',
  backEndDeveloperDescription: 'As a back-end web developer, you would be responsible for server-side web '
    + 'application logic. Back-end developers write the web services and APIs used by '
    + 'front-end and mobile application developers.',
  frontEndDeveloper: 'Front-End Developer',
  frontEndDeveloperDescription: 'As a front-end web developer, you would be responsible for implementing visual '
    + 'elements that users see and interact within a web application. They are usually '
    + 'supported by back-end web developers at the moment of integrating the functionalities.',
  qaDeveloper: 'Quality Assurance Developer',
  qaDeveloperDescription: 'As a quality assurance developer, you would be responsible for reviewing quality '
    + 'specifications and technical design documents to provide timely and meaningful '
    + 'feedback. Creating detailed, comprehensive and well-structured test plans and '
    + 'test cases. Estimating, prioritizing, planning and coordinating quality testing '
    + 'activities. Writing functional and unit tests.',

  // About us
  aboutUs: 'About us',
  aboutUsDescription: 'Coding ideas as digital solutions',

  // Footer
  newsletter: 'NEWSLETTER',
  subscribe: 'Subscribe',
  home: 'Home',
  customerStoriesFooter: 'Customer Stories',
  servicesPage: 'Services',
  alreadySubscribed: 'Email already subscribed',
  thanksForSubscribe: 'Thanks for subscribing!',
  emailSuccess: 'Email sent!',
  required: 'Required',
  requiredCheckbox: 'Required, choose at least one option',
  dollar: 'us dollar',
  newsletterEmail: 'Newsletter',
  unsubscribe: 'Unsubscribe',
  unsubscribeMessage: 'We are sorry to see you go.',
  confirm: 'Confirm',
  cancel: 'Cancel',
  emailError: 'Something went wrong'
};

export default resourceEn;
