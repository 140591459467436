module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":true,"disableMinification":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dekoding-web","short_name":"starter","start_url":"/","background_color":"#124AB1","theme_color":"#124AB1","display":"minimal-ui","icon":"src/assets/svg/logo.svg","icon_options":{"purpose":"maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"99856949d389e79429ea2d3edac87aad"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/dev/dekoding_web/dekoding_web_frontend/src/components/Layout/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.1,"once":true,"disable":false,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 50%","enterEventName":"sal:in"},
    },{
      plugin: require('../node_modules/gatsby-v2-plugin-page-transitions/gatsby-browser.js'),
      options: {"plugins":[],"transitionTime":500},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
