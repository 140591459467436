// mui-root-wrapper.js

import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'

import theme from './src/theme/theme'
import { ApolloProvider } from '@apollo/client';
import { client } from './src/apollo/client';

export const wrapRootElement = ({element}) => (
  <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </ThemeProvider>
)
