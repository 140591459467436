import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import scrollTo from 'gatsby-plugin-smoothscroll';
import PageTransition from 'gatsby-v2-plugin-page-transitions';

import './layout.css';
import '../../assets/styles/keyframes';
import Scroll from '../Scroll';


const Layout = ({children, style, subItemId}) => {

  useEffect(() => {
    if (subItemId) {
      scrollTo(`#${subItemId}`, 'start');
    }
  }, [subItemId]);

  return (
    <PageTransition>
      <div style={style || null}>
        {children}
        <Scroll showBelow={250} />
      </div>
    </PageTransition>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
