import { createMuiTheme } from '@material-ui/core';
import { esES } from '@material-ui/core/locale';

const theme = createMuiTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1024,
        lg: 1600, // 1440
        xl: 1920,
      },
    },
    typography: {
      fontFamily: [
        'Montserrat',
        'sans serif'
      ].join(','),
      h1: {
        fontSize: 57,
        fontWeight: 800,
        color: '#666666',
      },
      h2: {
        fontSize: 40,
        fontWeight: 800,
        textTransform: 'uppercase',
      },
      h3: {
        fontSize: 35,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        lineHeight: 1.5,
      },
      h4: {
        fontWeight: 'bold',
        fontSize: 27,
      },
      h5: {
        fontSize: 22,
        fontWeight: 300,
        color: 'white',
      },
      h6: {
        fontSize: 20,
        fontWeight: 'normal',
      },
      subtitle1: {
        fontSize: 15,
        fontWeight: 'bold',
        lineHeight: 'normal',
        color: '#333238',
        paddingBottom: '1rem',
      },
      subtitle2: {
        color: '#4D4D4D',
        fontWeight: 600,
        padding: '.5rem 0',
        opacity: .5,
        fontSize: 17,
      },
      body1: {
        fontSize: 16,
        color: '#3D4047',
        lineHeight: 1.8,
      },
      body2: {
        fontSize: 15,
        fontWeight: 400,
        color: '#35383F',
        lineHeight: 1.6,
      },
      caption: {
        fontSize: 12.8,
        fontWeight: 'normal',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [
            'Montserrat, sans serif'
          ],
          '*::-webkit-scrollbar': {
            width: 4,
          },
          '*::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: '#e0e0e0',
          },
          '*::-webkit-scrollbar-track': {
            borderRadius: 16,
            backgroundColor: 'transparent',
          },
        },
      },
      MuiButton: {
        root: {
          borderRadius: 16,
          boxSizing: 'border-box',
          textTransform: 'none',
        },
        contained: {
          disabled: {
            backgroundColor: 'inherit'
          }
        }
      },
      MuiSnackbar: {
        root: {
          zIndex: 100000000000,
        },
      },
    },
    palette: {
      primary: {
        main: '#124AB1',
        light: '#60C7F8',
        dark: '#022055',
        contrastText: '#fff',
        contrastTextLess: '#6B7C9A',
      },
      secondary: {
        main: '#fff',
        light: '#F9FAFF',
        contrastText: '#fff',
      },
      border: '#EAECF1',
      cardWhite: '#F2F3F8',
      cardOrange: '#F1AA17',
      cardBlue: '#60C6F8',
      linearProgress: '#A3AFB5',
    },
    spacing: 10,
  },
  esES
);

export default theme;
