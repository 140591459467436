export const keyframes = {
  slideUp: {
    position: 'relative',
    width: props => props.width,
    height: props => props.width,
    animation: '$slideUp 1s 1'
  },
  slideLeft: {
    position: 'relative',
    width: props => props.width,
    height: props => props.width,
    animation: '$slideLeft 1s 1'
  },
  slideDown: {
    position: 'relative',
    width: props => props.width,
    height: props => props.width,
    // opacity: 0,
    animationDelay: '1s',
    animation: '$slideDown 1s 1',
  },
  translateLeft: {
    position: 'relative',
    width: props => props.width,
    height: props => props.width,
    animation: '$translateLeft 1s infinite'
  },
  scaleLeft: {
    position: 'relative',
    width: props => props.width,
    height: props => props.width,
    animation: '$scaleLeft 1s 2',
    transformOrigin: '100% 50%',
    transition: 'all 500ms cubic-bezier(0.550, 0.085, 0.680, 0.530)',
  },
  scaleRight: {
    position: 'relative',
    width: props => props.width,
    height: props => props.width,
    animation: '$scaleLeft 1s 2',
    transformOrigin: '0% 100%',
  },
  '@keyframes slideUp': {
    from: {
      top: '50px',
      opacity: 0,
    },
    to: {
      top: '0px',
      opacity: 1,
    }
  },
  '@keyframes slideLeft': {
    from: {
      opacity: 0,
      transform: 'translateX(500px)',
    },
    to: {
      opacity: 1,
      transform: 'translateX(0)',
    }
  },
  '@keyframes slideRight': {
    from: {
      opacity: 0,
      transform: 'translateX(-500px)',
    },
    to: {
      opacity: 1,
      transform: 'translateX(0)',
    }
  },
  '@keyframes slideDown': {
    from: {
      opacity: 0,
      // top: '50px',
      transform: 'translateY(-50px)',
    },
    to: {
      opacity: '1 !important',
      // top: '0px',
      transform: 'translateY(0px)',
    }
  },
  '@keyframes translateLeft': {
    from: {
      opacity: 0,
      // top: '50px',
      overflow: 'hidden',
      transform: 'translateX(-50px)',
    },
    to: {
      opacity: '1 !important',
      transform: 'translateX(0px)',
    }
  },
  '@keyframes scaleLeft': {
    from: {
      opacity: 0,
      transform: 'scale(0)',
    },
    to: {
      opacity: '1 !important',
      transform: 'scale(1)',
    }
  }
};
