/*
  Spanish Resource
 */

const resourceES = {
  //Home
  homeTitle: 'Desarrollo de Software y Subcontratación de TI',
  homeSubTitle: 'Codificando ideas como soluciones digitales',

  // Services
  ourServices: 'Nuestros servicios',
  ourServicesDescription: 'Trabajamos con nuestros clientes de manera proactiva en el desarrollo de sus iniciativas.',
  softwareDevelopment: 'Desarrollo de software',
  softwareDevelopmentDescription: 'Hoy en día, el mundo reconoce que el software es necesario y cómo hasta las barreras más antiguas se han roto con la tecnología. Para desarrollar con calidad, rapidez y creatividad, consulte nuestros servicios de desarrollo de software.',
  softwareTesting: 'Pruebas de Software',
  softwareTestingDescription: 'La calidad es una de las cosas más importantes en cualquier software y también es uno de los núcleos principales de nuestro negocio. Nuestros servicios de pruebas y control de calidad de software pueden ayudarlo a asegurarse de que tiene un producto de calidad.',
  consultancyInnovation: 'Consultoría e Innovación',
  consultancyInnovationDescription: 'Mantenerse actualizado y a la vanguardia es una prioridad para competir en el mercado global. '
    + 'Los servicios de consultoría e innovación de Dekoding combinan nuestra profunda experiencia técnica, con un Desarrollo Ágil, junto un gran equipo y el Pensamiento de Diseño para hacer que su negocio sea parte del nuevo mundo.',
  userExperience: 'Experiencia de usuario',
  userExperienceDescription: 'Captar al cliente a primera vista, es un paso crucial que determina el éxito '
    + 'o falla de su aplicación. Para nosotros, UI y UX es una prioridad para desarrollar cualquier solución.',
  cloudServices: 'Servicios en la Nube',
  cloudServicesDescription: 'La Computación en la Nube viene para quedarse aquí y hacer que su empresa sea más ágil, flexible '
    + 'y receptiva a las cambiantes demandas del mercado. Con nuestra experiencia y conocimiento serás parte de este avance tecnológico. ',

  // Why dekoding
  whyDekoding: '¿Por qué Dekoding?',
  // purposeTitle: 'Presencia internacional',
  purposeDescription: 'Contamos con un equipo capacitado con años de experiencia que se enfoca en brindar '
    + 'servicios y soluciones de calidad, teniendo control inmediato de todos nuestros procesos desde el inicio al final '
    + 'logrando que nuestros clientes consigan sus objetivos con el producto final',


  // Stories
  customerStories: 'Some of our customer stories',
  storiesCard1: 'Ensuring a compelling user experience for a global entertainment company',
  storiesCard1Description: 'In today\'s world, software products must delight their users. To develop with speed and creativity, look to our software development services.',
  storiesCard2: 'Ensuring a compelling user experience for a global entertainment company',
  storiesCard2Description: 'In today\'s world, software products must delight their users. To develop with speed and creativity, look to our software development services.',
  storiesCard3Development: 'Ensuring a compelling user experience for a global entertainment company',
  storiesCard3Description: 'In today\'s world, software products must delight their users. To develop with speed and creativity, look to our software development services.',
  storiesCard4Development: 'Ensuring a compelling user experience for a global entertainment company',
  storiesCard4Description: 'In today\'s world, software products must delight their users. To develop with speed and creativity, look to our software development services.',
  readStory: 'Read the story',
  readMore: 'Read More',

  // Industries
  industries: 'Industrias',
  industriesDescription: 'Desde la industria financiera hasta la agrícola, hemos colaborado con nuestros '
    + 'clientes para crear soluciones digitales de calidad. '
    + 'Por lo tanto, hemos recopilado conocimiento de múltiples mercados para ayudarlo a construir un producto que tendrá impacto en el mercado',
  industriesCard1: '¿Por qué Dekoding?',
  industriesCard1Description: 'Contamos con un equipo capacitado con años de experiencia que se enfoca en brindar '
    + 'servicios y soluciones de calidad, teniendo control inmediato de todos nuestros procesos desde el inicio al final '
    + 'logrando que nuestros clientes consigan sus objetivos con el producto final',
  industriesCard2: 'Misión',
  industriesCard2Description: 'Proporcionar soluciones de TI a todo tipo de industrias en el desarrollo de software, '
    + 'dando servicios con los más altos estándares de calidad.',
  industriesCard3: 'Visión',
  industriesCard3Description: 'Ser líderes en el mercado Peruano lanzando nuestros proyectos de software con la ayuda '
    + 'de nuestro excelente equipo, así obtener mejores beneficios en el camino.',
  industriesCard4: 'Valores de la empresa',
  industriesCard4Description: 'Respeto: escuchamos, entendemos y valoramos a cada uno, buscando la armonía en nuestras relaciones interpersonales, '
    + 'laborales y comerciales. <0 /> Honestidad: hacemos todo con transparencia y rectitud.  <0 />'
    + 'Compromiso: Nuestro equipo está totalmente comprometido con nuestros clientes para brindar servicios de calidad',

  // Location
  ourLocation: 'Nuestras Locaciones',
  ourLocationDescription: 'Un centro de desarrollo en América del Sur y completamente remoto.',

  // Industries Services
  ourServicesIndustries: 'Proveemos el servicio de desarrollar software y aplicaciones móviles.'
    + 'Lo llevamos desde la creación del concepto y el diseño hasta el desarrollo y la entrega del producto final que sorprenderá a sus clientes.',
  cardList11: 'Servicios de Desarrollo de Productos',
  cardList12: 'Desarrollo Móvil',
  cardList13: 'Arquitectura de Software',
  cardList21: 'Pruebas y Control de Calidad de software',
  cardList22: 'Pruebas de Automatización',
  cardList23: 'Pruebas Móviles',
  cardList31: 'Centro de Exelencia',
  cardList32: 'Inteligencia Artificial y Aprendizaje Automático',
  cardList33: 'Servicios de Blockchain',
  cardList41: 'Servicios Agiles de UX',
  cardList42: 'Centro de Excelencia UX',
  cardList51: 'Desarrollo de Software en la Nube',
  cardList52: 'Servicios de Gestión en la Nube',
  cardList53: '',

  // Lets get to work
  letsGetToWork: 'Pongámonos a trabajar',
  contactUs: 'Contáctanos',
  letsGetToWorkDescription: 'Nuestra experiencia te ayudará a tener éxito en cualquier mercado. Contáctenos y uno de ' +
    'nuestros expertos se comunicará con usted para descubrir cómo podemos llevar su proyecto al éxito.',

  // Services Contact Us
  servicesContactUs: 'Nuestra experiencia lo ayudará a tener éxito en lo digital.',

  // Our industry expertise
  ourIndustryExpertise: 'Nuestra experiencia en la industria',
  ourIndustryExpertiseDescription: 'Empresas de todo el mundo han confiado en nosotros para ayudarles a construir '
    + 'productos exitosos. La tecnología se puede utilizar en todas las industrias para impulsar la productividad y '
    + 'mejorar la eficacia y la eficiencia, ahorrando presupuesto y, lo que es más importante, ahorrando tiempo.',
  ourIndustryDescription: 'Sobre nosotros',
  ourIndustrySubDescription: 'Empezamos como una empresa local y ahora realizamos proyectos para el exterior',
  industryExpertiseCard1: 'Financiera',
  industryExpertiseCard1Description: 'Los bancos y las aseguradoras han empleado los mismos modelos de negocio '
    + 'relativamente estáticos y altamente rentables. Pero hoy se encuentran confrontados por todos lados por innovadores que buscan interrumpir el negocio.',
  industryExpertiseCard2: 'Medios y Entretenimiento',
  industryExpertiseCard2Description: 'Con la tecnología sacudiendo el panorama de la industria, los creadores de contenido '
    + 'buscan cada vez más rediseñar sus medios para lograr una mayor agilidad, flexibilidad, eficiencia operativa y beneficios de costos',
  industryExpertiseCard3: 'Cuidado de la salud',
  industryExpertiseCard3Description: 'Agilizar el trabajo de los médicos, optimizar los sistemas, mejorar los resultados '
    + 'de los pacientes, reducir los errores humanos y reducir los costos a través de la tecnología.',
  industryExpertiseCard4: 'Educación',
  industryExpertiseCard4Description: 'La tecnología brinda a los maestros, padres y estudiantes información de fácil acceso, '
    + ' aprendizaje acelerado y oportunidades divertidas para practicar lo que han aprendido.',
  industryExpertiseCard5: 'Agricultura',
  industryExpertiseCard5Description: 'Una de las industrias más antiguas puede superar la creciente demanda y las fuerzas disruptivas mediante el uso de tecnología para mejorar su desempeño de muchas maneras.',
  industryExpertiseCard6: 'Bienes raíces',
  industryExpertiseCard6Description: 'Con la creciente inversión en Realtech, los operadores tradicionales se verán sometidos a una presión cada vez mayor para adaptarse o perecer. '
    + 'El nuevo campo de batalla en el sector inmobiliario es la tecnología.',

  // See Also
  seeAlso: 'See Also',
  seeAlsoItem1: 'Lorem ipsum',
  seeAlsoSubItem11: 'Lorem ipsum',
  seeAlsoSubItem11Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem12: 'Lorem ipsum',
  seeAlsoSubItem12Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem13: 'Lorem ipsum',
  seeAlsoSubItem13Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoItem2: 'Lorem ipsum',
  seeAlsoSubItem21: 'Lorem ipsum',
  seeAlsoSubItem21Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem22: 'Lorem ipsum',
  seeAlsoSubItem22Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem23: 'Lorem ipsum',
  seeAlsoSubItem23Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoItem3: 'Lorem ipsum',
  seeAlsoSubItem31: 'Lorem ipsum',
  seeAlsoSubItem31Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem32: 'Lorem ipsum',
  seeAlsoSubItem32Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem33: 'Lorem ipsum',
  seeAlsoSubItem33Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoItem4: 'Lorem ipsum',
  seeAlsoSubItem41: 'Lorem ipsum',
  seeAlsoSubItem41Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem42: 'Lorem ipsum',
  seeAlsoSubItem42Description: 'Lorem ipsum dolor sit amet, consetetur ...',
  seeAlsoSubItem43: 'Lorem ipsum',
  seeAlsoSubItem43Description: 'Lorem ipsum dolor sit amet, consetetur ...',

  // Careers
  careerTitle: 'Carreras',
  careerDescription: 'Sea parte de nuestro equipo de talentosos miembros',
  careers: 'Descripción del trabajo',
  careersDescription: 'Revisa nuestros perfiles y elige el que más se acomode a ti',
  careerItem1: 'Servicios de Desarrollo de Productos',
  careerItem2: 'Desarrollo Móvil',
  careerItem3: 'Arquitectura de Software',

  // Career form
  fullName: 'Nombre Completo',
  personalEmail: 'Correo Personal',
  message: 'Mensaje',
  cellphone: 'Celular',
  email: 'Correo',
  validCellphone: 'Celular inválido',
  validEmail: 'Correo inválido',
  salaryPerMonth: 'Expectativa salarial por mes',
  salaryInitInvalid: 'Salario inválido',
  fieldToAttachCV: 'Adjuntar CV',
  formFileMessage: 'Archivo PDF o Doc ',
  formMessage: 'El contenido de este formulario y los adjuntos son confidenciales. Están destinados solo a nuestro equipo de RR.HH.',
  sendForm: 'Enviar formulario',
  send: 'Enviar',
  leadDeveloper: 'Desarrollador Lead',
  leadDeveloperDescription: 'Como desarrollador líder, serías responsable de dirigir al equipo en el diseño, desarrollo, '
    + 'codificación, prueba y depuración de aplicaciones. También coordinaría el cronograma del personal de desarrollo y '
    + 'garantizaría una comunicación efectiva entre los miembros del equipo y otras áreas funcionales de TI.',
  backEndDeveloper: 'Desarrollador Back-End',
  backEndDeveloperDescription: 'Como desarrollador back-end serías responsable de la lógica de la aplicación web del lado del servidor. '
    + 'Los desarrolladores de back-end escriben los servicios web y las API que utilizan los desarrolladores de aplicaciones móviles y de front-end.',
  frontEndDeveloper: 'Desarrollador Front-End',
  frontEndDeveloperDescription: 'Como desarrollador front-end, serías responsable de implementar elementos visuales '
    + 'con los que los usuarios interactuan en la aplicación web. '
    + 'Suelen contar con el apoyo de desarrolladores back-end al momento de integrar las funcionalidades.',
  qaDeveloper: 'Desarrollador Aseguración de Calidad',
  qaDeveloperDescription: 'Como desarrollador de la aseguración de la calidad de software, serías responsable de revisar '
    + 'las especificaciones de calidad y los documentos de diseño técnico para brindar una retroalimentación oportuna y significativa. '
    + 'Creando casos de prueba detallados, comprensivos y bien estructurados. Estimarías, priorizarías, planificarías y coordinarías actividades de pruebas de calidad. '
    + 'Escribiendo pruebas funcionales y unitarias.',

  // About us
  aboutUs: 'Sobre nosotros',
  aboutUsDescription: 'Codificando ideas como soluciones digitales',

  // Footer
  newsletter: 'BOLETÍN INFORMATIVO',
  subscribe: 'Suscribirse',
  home: 'Inicio',
  customerStoriesFooter: 'Historias de nuestros Clientes',
  servicesPage: 'Servicios',
  alreadySubscribed: 'Este email ya está suscrito',
  thanksForSubscribe: '¡Gracias por suscribirse!',
  emailSuccess: '¡Correo enviado!',
  required: 'Requerido',
  requiredCheckbox: 'Requerido, selecciona al menos una de las opciones',
  dollar: 'dólar americano',
  newsletterEmail: 'Boletín de noticias',
  dekodingTeam: 'Dekoding',
  unsubscribe: 'Desuscribirse',
  unsubscribeMessage: 'Lamentamos verte partir.',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  emailError: 'Algo salió mal'
};


export default resourceES;
